<template>
  <div class="service-category-setting">
    <!-- <PageTitle
      title="商品項目設定"
      btn="新增"
      btn2="Rezio資料同步"
      btn2-feature="admin.rezio.sync"
      @btnClick="$router.push({name: 'ProductCreate'})"
      @btn2Click="syncAllProduct"
    /> -->
    <div class="page-title">
      <div class="flex items-center title">
        <span class="title" style="font-size: 18px">商品項目設定</span>
      </div>
      <div class="grid grid-flow-col gap-[12px]">
        <div class="flex items-center" v-if="showEcountSyncBtn">
          <p class="text-sub text-gray-60 mr-[12px]">(包含更新會員端顯示的庫存數量)</p>
          <BaseElButton
            v-if="showEcountSyncBtn"
            class="btn page-title-btn"
            plain
            testName="ecount_sync"
            @click="syncEcountProduct"
            :loading="ecountLoading"
          >
          更新庫存數量
          </BaseElButton>
        </div>
        <BaseElButton
          v-if="showRezioSyncBtn"
          class="btn page-title-btn"
          plain
          testName="rezio_sync"
          @click="syncAllProduct"
        >
          Rezio資料同步
        </BaseElButton>
        <BaseElButton
          class="btn page-title-btn"
          type="primary"
          testName="create_product"
          @click="$router.push({name: 'ProductCreate'})"
        >
          新增
        </BaseElButton>
      </div>
    </div>
    <FiltersContainer>
      <BaseElInput
        v-model="nameSearch"
        clearable
        placeholder="輸入商品名稱"
        @keypress.enter.native="refresh"
        @clear="refresh"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh"
        />
      </BaseElInput>
      <BaseElInput
        v-if="showEcountSyncBtn"
        v-model="productNoSearch"
        clearable
        placeholder="搜尋商品編號"
        @keypress.enter.native="refresh"
        @clear="refresh"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh"
        />
      </BaseElInput>
    </FiltersContainer>

    <section>
      <p>目前商品總數：{{ productCategoryCount }} / 合約方案商品上架上限：{{ productLimit }}</p>
      <ProgressBar :percentage="productLimitPercentage" />
    </section>

    <section>
      <BaseTable
        v-loading="loading"
        :data="productCategoryList"
        empty-text="暫無數據"
      >
        <EmptyBlock slot="empty" />
        <BaseElTableColumn prop="MainImage" label="圖片" align="center">
          <template slot-scope="scope">
            <img
              style="margin: auto;"
              :width="`${imgSize}px`"
              :src="imgSource(scope.row.MainImage)"
              alt=""
            >
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="name" label="名稱" align="center" />
        <!-- <BaseElTableColumn
          prop="description"
          label="敘述"
          align="center"
          show-overflow-tooltip
        /> -->
        <BaseElTableColumn prop="isPublic" label="公開顯示" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.isPublic)">
              {{
                scope.row.isPublic ? '是' : '否'
              }}
            </Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="activityPrice" label="活動價" align="center" />
        <BaseElTableColumn prop="price" label="原價" align="center" />
        <BaseElTableColumn prop="order" label="排序" align="center" />
        <!-- <BaseElTableColumn prop="order" label="活動優惠" align="center" /> -->
        <BaseElTableColumn label="操作" fixed="right" width="110" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              @edit="$router.push(`/product-modify/${scope.row.id}`)"
              @delete=";(deleteDialog = true), (selectRow = scope.row)"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="productCategoryCount"
        @pageChange="refresh"
      />
    </section>

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteServiceCategory(), (deleteDialog = false)"
    />
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { mapGetters } from 'vuex'
import EmptyBlock from '@/components/EmptyBlock.vue'
import ProgressBar from '@/components/Progress/ProgressBar'
import {
  CreateProduct,
  GetProduct,
  GetProductCount,
  FindProduct,
  UpdateProduct,
  DeleteProduct,
} from '@/api/ecommerce/product'
import { SyncExternalProductNo } from '@/api/ecount'
import {
  SyncAllRezioProduct,
} from '@/api/ecommerce/rezioProduct'
import DeleteDialog from '@/components/Dialog/DeleteDialog'

// Utils
import { pageStartIndex } from '@/utils/table'
import formUtils from '@/utils/form'
import { dialogTitle } from '@/utils/dialog'
import { extractList, imgSrc } from '@/utils/helper'
import { usePermissions } from '@/use/permissions'
import { useEcount } from '@/use/useEcount'
// import * as cssvars from '@/styles/ohbot/_variables.scss'
import { get } from 'lodash'
import { useShop } from '@/use/shop'

export default {
  name: 'ServiceCategorySetting',
  components: { DeleteDialog, EmptyBlock, ProgressBar },
  setup() {
    const { shopId } = useShop()
    const { checkAction } = usePermissions()
    const { ecountConfig, getEcountConfig } = useEcount()
    const showRezioSyncBtn = computed(() => checkAction('admin.rezio.sync'))
    const showEcountSyncBtn = computed(() => checkAction('admin.shopCustomModule.updateEcountClient') && get(ecountConfig.value, 'enabled'))
    const ecountLoading = ref(false)
    const syncEcountProduct = async () => {
      ecountLoading.value = true
      const [res, err] = await SyncExternalProductNo({
        shopId: shopId.value,
      })
      ecountLoading.value = false
      if (err) return window.$message.error(err)
      if (res && res.success) window.$message.success('更新成功')
    }
    onMounted(async() => {
      await getEcountConfig()
    })
    return {
      showRezioSyncBtn,
      showEcountSyncBtn,
      syncEcountProduct,
      ecountLoading,
    }
  },
  computed: {
    ...mapGetters([
      'shop',
      'userPlanLimit',
    ]),
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
    dialogTitle () {
      return dialogTitle(this.dialogType, {
        create: '新增產品類別',
        update: '編輯產品類別',
      })
    },
    productsList () {
      if (this.formData.services.length === 0) return []
      return extractList('id', this.formData.services)
    },
    productLimit () {
      return this.userPlanLimit.ecommerceProductLimit
    },
    productLimitPercentage () {
      if (this.userPlanLimit.ecommerceProductLimit === 0) {
        return 0
      }
      const count = Math.round(this.productCategoryCount * 100 / this.userPlanLimit.ecommerceProductLimit)
      return count > 100 ? 100 : count
    },
  },
  data: () => ({
    loading: false,
    nameSearch: '',
    productNoSearch: '',
    serialCodeSearch: '',
    isEnable: '',
    uploadDialog: false,
    showDialog: false,
    deleteDialog: false,
    dialogType: '',
    imgSize: 90,
    productCategoryList: [],
    productCategoryCount: 0,

    selectRow: null,
    tableOptions: {
      page: 1,
      pageLimit: 10,
    },

    avatarChanged: false,

    formData: {
      img: null,
      isPublic: true,
      avatar: null,
      name: '',
      order: 100,
      id: '',
      description: '',
      ecommerceActivity: '',
      ecommerceCategory: '',
      ecommerceProductParentSpecs: '',
      ecommerceProductChildSpecs: '',
      ecommerceProductStocks: 0,
    },

    colorList: [{
      color: '#1989fa',
      percentage: 80,
    },
    {
      color: '#f56c6c',
      percentage: 100,
    },
    ],
  }),

  async mounted () {
    await this.refresh()
  },
  async activated () {
    await this.refresh()
  },

  methods: {
    tagType (val) {
      let type = 'info'
      if (val) type = 'action'
      // if (!val) type = 'danger'
      return type
    },

    imgSource (image) {
      return imgSrc(this.imgSize, image)
    },

    getImage (data) {
      this.formData.avatar = data
      this.avatarChanged = true
      this.uploadDialog = false
    },

    loadImg (img) {
      this.formData.img = img
      this.uploadDialog = true
    },

    async refresh () {
      this.loading = true
      await this.getServiceCategory()
      await this.getServiceCategoryCount()
      this.loading = false
    },

    syncFormData (row) {
      this.formData.name = row.name
      this.formData.order = row.order
      this.formData.id = row.id
      this.formData.isPublic = row.isPublic
      this.formData.avatar = row.Image
    },

    //= > 檢查表單輸入驗證
    async checkForm () {
      return await formUtils.checkForm(this.$refs.form)
    },

    //= > 重置表單
    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.formData = {
        img: null,
        isPublic: true,
        avatar: null,
        name: '',
        order: 100,
        id: '',
        services: [],
      }
    },

    async dialogConfirm () {
      if (!(await this.checkForm())) return
      const type = this.dialogType
      if (type === 'create') this.createServiceCategory()
      if (type === 'update') this.updateServiceCategory()
      this.showDialog = false
    },

    async syncAllProduct () {
      this.loading = true
      const [_, error] = await SyncAllRezioProduct({
        shopId: this.shop,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      this.$message.success('同步成功')
    },

    //= > 取得產品類別
    async getServiceCategory () {
      this.loading = true
      const startIndex = this.pageStartIndex
      const limit = this.tableOptions.pageLimit
      const [res, error] = await GetProduct({
        shopId: this.shop,
        start: startIndex,
        limit,
        name: this.nameSearch === '' ? undefined : this.nameSearch,
        productNo: this.productNoSearch === '' ? undefined : this.productNoSearch,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      this.productCategoryList = res
    },

    //= > 創建產品類別
    async createServiceCategory () {
      this.loading = true
      const [res, error] = await CreateProduct({
        shopId: this.shop,
        name: this.formData.name,
        // order: this.formData.order,
        mainImage: this.formData.avatar ? this.formData.avatar.id : undefined,
        description: this.formData.description,
        showActivityPrice: false,
        isPublic: this.formData.isPublic,
        ecommerceActivity: this.formData.ecommerceActivity,
        ecommerceCategory: this.formData.ecommerceCategory,
        ecommerceProductParentSpecs: this.formData.ecommerceProductParentSpecs,
        ecommerceProductChildSpecs: this.formData.ecommerceProductChildSpecs,
        ecommerceProductStocks: this.formData.ecommerceProductStocks,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      console.log(res)
      await this.refresh()
      this.$message.success('新增成功')
    },

    async findServiceCategory () {
      const [res, error] = await FindProduct({
        shopId: this.shop,
        id: this.selectRow.id,
      })
      if (error) return this.$message.error(error)
      console.log(res)
      // this.formData.services = res.AppointmentServices.filter(item => ! item.isRemove)
    },

    //= > 刪除產品類別
    async deleteServiceCategory () {
      this.loading = true
      const [res, error] = await DeleteProduct({
        shopId: this.shop,
        id: this.selectRow.id,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      console.log(res)
      await this.refresh()
      this.$message.success('刪除成功')
    },
    //= > 更新產品類別
    async updateServiceCategory () {
      this.loading = true
      const [res, error] = await UpdateProduct({
        shopId: this.selectRow.ShopId,
        id: this.selectRow.id,
        name: this.formData.name,
        order: this.formData.order,
        image: this.avatarChanged ? this.formData.avatar.id : undefined,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      console.log(res)
      await this.refresh()
      this.$message.success('更新成功')
    },

    //= > 取得服務人員總數
    async getServiceCategoryCount () {
      this.loading = true
      const [res, error] = await GetProductCount({
        shopId: this.shop,
        name: this.nameSearch === '' ? undefined : this.nameSearch,
        productNo: this.productNoSearch === '' ? undefined : this.productNoSearch,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      this.productCategoryCount = res.count
    },

    async openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },
  },
}
</script>

<style scoped lang="scss">
.page-title {
  @apply flex justify-between mb-[24px];
}

.title {
  @apply font-medium;
}

.btn {
  @apply min-w-[100px] text-normal;
}
</style>
